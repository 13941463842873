<template>
  <div class="container">
    <div class="card">
      <h5 class="card-title text-center">
        {{ $t("page.404.header") }}
      </h5>

      <div class="card-body text-center">
        <button class="btn btn-info" @click="handleChangeLang">
          {{ $t("button.goToMainPage") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Error404",

  methods: {
    handleChangeLang() {
      this.$router.push(`${this.getCurrentLang}/${this.getSteps[0].name}`);
    },
  },

  computed: {
    ...mapGetters("app", ["getCurrentLang"]),
    ...mapGetters("form", ["getSteps"]),
  },
};
</script>
